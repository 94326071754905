import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import SocialProfile from "../../components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/joanmoralesb",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoLinkedin />,
    url: "https://www.linkedin.com/in/joanmb/",
    tooltip: "LinkedIn",
  },
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About me</h2>
      </AboutPageTitle>


      <AboutDetails>
        <p>I'm Joan Morales, a Salesforce developer, blockchain enthusiast and former Android developer, currently working at SCRM - Lidl International Hub in <a href="https://en.wikipedia.org/wiki/Barcelona">Barcelona</a>, Spain</p>

        <p>I started this website as a place to document all the things that I find interesting about Salesforce development, including articles about certfications, or how I managed to solve some tricky problems in SF that other people can have.
        <br/>If you have an idea for an article that you think I can write, please don't hesitate to <Link to="/contact">contact me</Link>! </p>

        <p>This is my personal blog. All thoughts and opinions published here are my own.</p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
